@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Faden';
  src: local('Faden'), url('./fonts/faden.woff') format('woff'),
    url('./fonts/faden.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Light';
  src: local('Lato'), url('./fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato'), url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),
    url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

body {
  background-color: '#f5f5f5';
}

html,
body,
#root {
  height: 100%;
  font-family: 'Lato', 'sans-serif';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* MODAL STYLING */
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
  position: relative;
  top: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 60%;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
}

.sidebar:before {
  content: '';
  position: absolute;
  width: 220px;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: white;
  border: none;
}

.Toastify__toast--success {
  background-color: #00984b !important;
}

.Toastify__toast--error {
  background-color: #d93400 !important;
}
#requestForm .new {
  @apply bg-blue-100;
}

input[type='text'][disabled],
textarea:disabled {
  @apply bg-gray-200;
}
.ft-check {
  @apply hidden;
}
.ft-check-small {
  @apply hidden;
}

.ft-check-weaving {
  @apply hidden;
}

.ft-sign {
  @apply hidden;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  width: 140px;
  background-color: rgb(253 230 138);
  color: rgb(180 83 9);
  text-align: center;
  left: 50%;
  margin-left: -70px;
  margin-bottom: 10px;
  bottom: 100%;
  padding: 5px;
  border: 2px solid rgb(180 83 9);
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-weight: 600;
}

.tooltip .tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(180 83 9) transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

@media print {
  nav,
  header,
  .logo-gestinet {
    display: none !important;
  }
  .rams-break {
    page-break-before: always !important;
  }
  .print-flex {
    display: block !important;
  }
  .bg-gray-100 {
    background-color: white !important;
  }
  /* FT ACABATS */
  .descripcio-ram {
    display: grid;
  }
  .ft-check {
    flex: 0 0 15px;
    height: 15px;
    width: 15px;
    /* margin-left: auto; */
    /* left: 5px; */
    position: relative;
    @apply block;
  }

  .ft-check-small {
    flex: 0 0 2rem;
    height: 1.75rem;
    width: 1.75rem;
    margin-left: auto;
    left: 2px;
    position: relative;
    @apply block;
  }

  .ft-check-weaving {
    flex: 0 0 2rem;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: auto;
    position: absolute;
    @apply block;
  }

  #small-font-size-print {
    font-size: 7px !important;
  }

  .hidePrint {
    display: none !important;
  }
  .ft-sign {
    @apply flex;
  }

  input[type='text'][disabled],
  textarea:disabled {
    background-color: inherit;
  }

  /* FT TEIXIDURIA */
}

.faden {
  font-family: Faden;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.tableHeader {
  border: 1px solid #be0043;
}

@media print {
  .printField {
    border: 0px !important;
    margin-left: 0px !important;
    padding: 0px !important;
  }

  .printTr {
    display: flex;
    flex-direction: row;
    /* align-items: start; */
    align-items: flex-start;
    height: auto;
  }

  /* Diseño del 'pre' donde mostraremos los contenidos de los textareas: */
  PRE.print {
    width: 95%; /* fixes margin issues in some funky browsers */
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: 'Lato';
    text-align: justify;
    /* padding: 12px 0px 12px 4px !important; */
    border: 0px !important;
    margin-left: 0px !important;
    padding: 5px 0px 5px 0px;
  }

  PRE.printJobTextAreas {
    width: 100%; /* fixes margin issues in some funky browsers */
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: 'Lato';
    text-align: justify;
  }

  table {
    page-break-inside: auto !important;
  }

  tr {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }

  .Toastify {
    display: none !important;
  }

  .selectOnPrint div div:nth-child(2) span,
  .selectOnPrint div div:nth-child(2) div svg {
    display: none !important;
  }

  .selectOnPrint div {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border: none !important;
    outline: none !important;
  }

  .bottomBoxes {
    page-break-inside: avoid !important;
  }
}

/* At Column Filter input -Show background colors if input valid (not empty) */
input:valid.columnFilter {
  background-color: #fee2e2;
  border: 2px solid #fca5a5;
}

input[type='text'][disabled] {
  background-color: #f2f2f2;
  color: #999999;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: #155fff;
  position: relative;
  padding-right: 120px;
  position: relative;
}

.switch-button:before {
  content: 'Video';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}

.switch-button-checkbox + .switch-button-label:before {
  content: '';
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}

.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

#sidebar-menu::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

#sidebar-menu::-webkit-scrollbar-track {
  background: #153a5b; /* color of the tracking area */
  padding: 5px 2px 5px 0px;
}

#sidebar-menu::-webkit-scrollbar-thumb {
  background-color: #000000; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

@media print {
  html {
    overflow-x: hidden !important;
    overflow-y: initial !important;
    -ms-overflow-style: none;
    transform: scale(0.95);
    width: 100% !important;
  }

  html,
  body {
    height: initial !important;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    font-size: 11px !important;
  }

  /* Para esconder 'scrollBar' */
  ::-webkit-scrollbar {
    display: none !important;
  }

  #technicalSheetFirstPagePrint {
    page-break-after: always;
    page-break-inside: avoid;
    height: 100%;
  }

  #technicalSheetSecondPagePrint {
    page-break-before: always;
    height: 100%;
  }

  @page {
    size: auto; /* Set page size to auto */
    margin: 0; /* Set margin to 0 */
  }

  /* Remove header and footer */
  @page :first {
    margin-top: 0;
  }

  @page :left {
    margin-left: 0;
  }

  @page :right {
    margin-right: 0;
  }

  .print-background {
    background-color: #ffffff !important;
    color: #333333 !important;
  }
}

.custom-disabled-field {
  background-color: #f2f2f2;
  color: #999999;
}

#scrollable-parent::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

#scrollable-parent::-webkit-scrollbar-track {
  background: rgb(219 234 254); /* color of the tracking area */
  padding: 5px 2px 5px 0px;
}

#scrollable-parent::-webkit-scrollbar-thumb {
  background-color: #153a5b; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

/* ----------------------------------- */
/* Teixiduria */
/* ----------------------------------- */
.block-input-disabled {
  @apply px-2 py-0.5 border border-gray-300 rounded-sm appearance-none;
  color: #6b7280 !important;
}

.block-input {
  @apply px-2 py-0.5 border border-gray-300 rounded-sm appearance-none outline-none shadow-none hover:border-gray-400 focus:border-gray-400;
}

.table-input-disabled {
  @apply px-0.5 py-1 border border-gray-300 rounded-sm appearance-none truncate;
  color: #6b7280 !important;
}

.table-input {
  @apply px-0.5 py-1 border border-gray-300 rounded-sm appearance-none outline-none shadow-none hover:border-gray-400 focus:border-gray-400 truncate;
}
/* ----------------------------------- */
/* Teixiduria */
/* ----------------------------------- */

/* Read-Only input fields */
.input-readonly {
  @apply p-1 text-sm truncate border border-gray-300 rounded-sm outline-none print:p-0.5;
  /* cursor: not-allowed !important; */
}

/* Editable input fields (No hover effects) */
.input-editable-standard {
  @apply px-2 py-0.5 border rounded-sm appearance-none outline-none shadow-none;
}

/* Editable input fields (With hover effects) */
.input-editable-interactive {
  @apply px-2 py-0.5 border border-gray-300 rounded-sm appearance-none outline-none shadow-none hover:border-gray-400 focus:border-gray-400;
}

/* Disabled input fields */
.input-disabled {
  @apply px-2 py-0.5 border border-gray-300 rounded-sm appearance-none;
  background-color: #f2f2f2 !important;
  color: #6b7280 !important;
}
